import React, {useState} from 'react';
import {
    Modal,
    Popover,
    Button,
    Checkbox,
    Form,
    Input,
    Typography,
    notification,
    Divider,
    Radio,
    Select,
    Card,
    Row, Col
} from 'antd';
import Icon, {
    InfoCircleOutlined,
    CheckOutlined,
    BellOutlined,
    CheckCircleFilled,
    ExclamationCircleFilled,
    WarningFilled, CloseCircleFilled, CheckCircleOutlined, AlertOutlined, CopyOutlined
} from "@ant-design/icons";

import axiosInstance from "../../services/axios";
import {useAuth} from "react-oidc-context";

const {Title, Text} = Typography;

const ResolveModal = (props) => {

    const auth = useAuth();
    const user = auth?.user?.profile
    const [form] = Form.useForm();
    const [comment, setComment] = useState('');
    const [createDamageEvent, setCreateDamageEvent] = useState(false);

    let editorOptions = [{value: (user?.name), label: (user?.name)}]

    const handleCancel = () => {
        setComment('')
        setCreateDamageEvent(false)
        props.hideModal && props.hideModal();
    };

    const onFinish = () => {
        const payload = {
            customer: props.customer,
            box_id: props.data.box_id,
            uaid: props.data.UAID,
            event_id: props.data.event_id,
            comment: comment,
            damage_event: props.resolveType === 'confirm' ? createDamageEvent : false,
            resolve_type: props.resolveType
        }

        axiosInstance.post('/api/geofunctions/alert/resolve', payload)
            .then(res => {
                notification['success']({
                    message: 'Success resolving Alert',
                    duration: 5,
                    maxCount: 1
                })

                setComment('')
                setCreateDamageEvent(false)

                props.setEventExists(payload.damage_event)
                if (props.resolveType === 'confirm') {
                    props.setAlertMatching(true)
                }
                if (props.resolveType === 'dismiss') {
                    props.setAlertMatching(false)
                }

                const newData = props.data

                newData.resolved = true
                newData.resolved_ts = new Date().toISOString()
                newData.inspection_result = comment

                props.setData(newData)
                props.setRefreshKey(props.refreshKey + 1)
                props.setUpdateCounter(prevCounter => prevCounter + 1);
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error', description: error.message});
            })

        props.hideModal();
    };

    return (
        <>
            <Modal
                open={props.open}
                onCancel={handleCancel}
                width={500}
                height={400}
                footer={null}
                style={{
                    left: '0%', // Adjust the percentage value to move the modal further left
                    top: '30%'
                }}
            >
                <Row>
                    <Col span={2}>
                        {props.resolveType === 'confirm' ?
                            <CheckCircleFilled style={{fontSize: 28, color: '#088ED7', marginRight: 32}}/> :
                            <CloseCircleFilled style={{fontSize: 28, color: '#E55555', marginRight: 32}}/>
                        }
                    </Col>
                    <Col span={22} style={{paddingLeft: 8}}>
                        <Title level={5} style={{marginTop: 4, marginBottom: 16}}>
                            {props.resolveType === 'confirm' ? 'Confirm Alert' : 'Dismiss Alert'}
                        </Title>
                        <Text style={{marginBottom: 4, marginRight: 100}}>
                            Comment
                        </Text>
                        <Input.TextArea
                            style={{marginTop: 4, marginBottom: 8, width: 404}}
                            rows={4}
                            placeholder="Type here"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                        {props.resolveType === 'confirm' &&
                            <Checkbox
                                checked={createDamageEvent}
                                onChange={(e) => setCreateDamageEvent(e.target.checked)}
                            >
                                Create damage event
                            </Checkbox>
                        }
                        <Row style={{marginTop: 32}} justify="end">
                            <Button style={{marginRight: 8}} onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button type={'primary'} onClick={onFinish}>
                                OK
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default ResolveModal;